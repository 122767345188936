export const getContratosPDF = (axios, payload) => axios.post('report/contrato', payload, {
    responseType: 'blob'
})

export const getApolicesPDF = (axios, payload) => axios.post('report/apolice', payload, {
    responseType: 'blob'
})

export const comissaoPDF = (axios, payload) => axios.post('report/comissao', payload, {
    responseType: 'blob'
})

export const rankingVendas = (axios, payload) => axios.post('report/ranking', payload, {
    responseType: 'blob'
})